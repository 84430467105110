<template>
  <v-container
    style="background:rgba(255,255,255,0.6); position:absolute; top:0; bottom:0; left:0; right:0;"
  >
    <v-row no-gutters style="font-size:18px;padding:0; margin:70px 0 0 0;">
      <v-col cols="12">
        <div class="d-flex flex-column justify-center align-center">
          <p style="margin:0; padding:0; font-size:50px;">
            SanQua Ekspedisi
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// import { mapMutations } from 'vuex'
export default {
  name: 'riterealization',
  components: {},
  data: () => ({
    loading: false
  }),
  async mounted() {},
  created() {},
  methods: {
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise(resolve => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then(r => {
            resolve(r)
          })
      })
    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 576px) {
}
@media (max-width: 320px) {
}
</style>
